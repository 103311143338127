// src/firebaseConfig.js
const firebaseConfig = {
    apiKey: "AIzaSyBYvq96BzbqNcLwdqfpI3CHet0NoDmNNR8",
    authDomain: "kedarnath-a9da6.firebaseapp.com",
    projectId: "kedarnath-a9da6",
    storageBucket: "kedarnath-a9da6.appspot.com",
    messagingSenderId: "259876327703",
    appId: "1:259876327703:web:a19bfa547cd12b29212752"
  };

 export default firebaseConfig;








//VERSION 2.00

// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID
// };

// export default firebaseConfig;

